import { updateStatusBarColor } from 'utils/updateStatusBarColor'
import SetNewPassword from 'pages/ResetPassword/SetNewPassword'
import { Route, Routes, useLocation } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import GoogleCallback from 'pages/GoogleCallback'
import AppleCallback from 'pages/AppleCallback'
import RestPassword from 'pages/ResetPassword'
import { useAuth } from 'contexts/AuthContext'
import { isIOS } from 'utils/nativeApp'
import QrScanner from 'pages/QrScanner'
import Dashboard from 'pages/Dashboard'
import Loader from 'components/Loader'
import Schedule from 'pages/Schedule'
import Notfound from 'pages/Notfound'
import QrReader from 'pages/QrReader'
import Gallery from 'pages/Gallery'
import Signin from 'pages/Signin'
import Verify from 'pages/Verify'
import Login from 'pages/Login'
import Home from 'pages/Home'
import Menu from 'pages/Menu'
import New from 'pages/New'
import Add from 'pages/Add'
import './App.scss'

const ios = isIOS()

const App: React.FC = () => {
  const { pathname } = useLocation()
  const [isLoading, setLoading] = useState(true)
  const { refreshToken } = useAuth()

  useEffect(() => {
    if (
      pathname.startsWith('/gallery') ||
      pathname.startsWith('/menu') ||
      pathname.startsWith('/schedule') ||
      pathname.startsWith('/add') ||
      pathname.startsWith('/new')
    ) {
      updateStatusBarColor('#F5F4F8')
    } else {
      updateStatusBarColor('#ffffff')
    }
  }, [pathname])

  useEffect(() => {
    const updateVH = () => {
      const vh = window.innerHeight * 0.01
      document.body.style.setProperty('--vh', `${vh}px`)
    }
    updateVH()
    window.addEventListener('resize', updateVH)
    return () => {
      window.removeEventListener('resize', updateVH)
    }
  }, [])

  useEffect(() => {
    if (!window.location.pathname.includes('auth')) {
      refreshToken().then(() => {
        setLoading(false)
      })
    } else {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className={`app${ios ? ' --ios' : ''}`}>
      {isLoading
        ? <Loader type='full' />
        : <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/add' element={<Add />} />
          <Route path='/new' element={<New />} />
          <Route path='/menu' element={<Menu />} />
          <Route path='/verify' element={<Verify />} />
          <Route path='/qr/*' element={<QrReader />} />
          <Route path='/dashboard/:id' element={<Dashboard />} />
          <Route path='/qrscanner' element={<QrScanner />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/schedule' element={<Schedule />} />

          <Route path='/login' element={<Login />} />
          <Route path='/signin' element={<Signin />} />
          <Route path='/reset' element={<RestPassword />} />
          <Route path='/password/reset' element={<SetNewPassword />} />
          <Route path="/auth/google" element={<GoogleCallback />} />
          <Route path="/auth/apple" element={<AppleCallback />} />

          <Route path='/notfound' element={<Notfound />} />
        </Routes>}
    </main>
  )
}

export default App
