import FormFooter from 'pages/Login/FormFooter'
import { useNavigate } from 'react-router-dom'
import SentModal from 'pages/Signin/SentModal'
import { POST } from 'api/request'
import { useState } from 'react'
import * as url from 'api/urls'

const RestPassword: React.FC = () => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()
  const [modal, setModal] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleSubmit = async () => {
    setSending(true)
    try {
      const response = await POST(url.forgotPassword, {
        body: {
          email,
          reseturl: `${window.location.origin}/password/reset`
        }
      })
      if (response.ok) {
        setModal(true)
        setSending(false)
      } else {
        console.error(response.data.errors)
      }
    } catch (err) {
      console.error(err)
    }
    setSending(false)
  }

  return (
    <>
      {modal && <SentModal title="Wysłano mail" content="Otwórz swoją skrzynkę pocztową i kliknij w link z wiadomości. Zostaniesz przeniesiony do strony, gdzie ustalisz nowe hasło." onClose={() => setModal(false)} />}
      <div className="login">
        <div className="login__desktop"></div>

        <div className="login__form">
          <div>
            <div className="login__head">
              <h1>Zresetuj hasło</h1>
            </div>

            <div className="login__body grow">
              <div className="myInput">
                <label>Podaj E-mail powiązany z kontem</label>
                <input
                  type="text"
                  value={email}
                  onChange={handleEmailChange}
                />
              </div>
              <div className='signin'>
                <div className="signin__wrap">Posiadasz konto? <span onClick={() => navigate('/login')}>Zaloguj się</span></div>
              </div>
              <button
                className={`btn btn--primary${sending ? ' disabled' : ''}`}
                onClick={handleSubmit}>
                {sending ? "Wysyłanie..." : "Zresetuj hasło"}
              </button>
            </div>

            <FormFooter />
          </div>
        </div>
      </div>
    </>)
}

export default RestPassword