import useFetchedData from 'hooks/useFetchedData'
import Loader from 'components/Loader'
import * as urls from 'api/urls'
import Modal from './Modal'
import React from 'react'

interface TermsModalProps {
  type: 'terms' | 'privacy'
  onClose: base.BasicFunc
}

const TermsModal: React.FC<TermsModalProps> = ({ type, onClose }) => {
  const { isLoading, data } = useFetchedData(urls[type], '', 'GET')
  return (
    <Modal
      isLargeModal
      title={type === 'privacy' ? 'Polityka prywatności' : 'Regulamin'}
      onClose={onClose}
      // secondaryButton='Zamknij'
      // secondaryButtonAction='close'
      >
      <div style={{ minHeight: '160px', margin: '1em' }}>
        {isLoading
          ? <Loader type='full' />
          : data && typeof data === 'string'
            ? <div dangerouslySetInnerHTML={{ __html: data }} />
            : <p>Coś poszło nie tak :/</p>}
      </div>
    </Modal>
  )
}

export default TermsModal