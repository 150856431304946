import SocialButtons from 'pages/Login/SocialButtons'
import Checkbox from 'components/inputs/Checkbox'
import LinkToTerms from 'components/LinkToTerms'
import { useModal } from 'contexts/ModalContext'
import FormFooter from 'pages/Login/FormFooter'
import React, { useRef, useState } from 'react'
import SentModal from './SentModal'
import { POST } from 'api/request'
import * as url from 'api/urls'

interface SigninFormProps {
  email: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  showLoginForm: (email?: string) => void
}

const DELAY = 500

const SigninForm: React.FC<SigninFormProps> = ({ email, setEmail, showLoginForm }) => {
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [checkPassword, setCheckPassword] = useState('')
  const [sending, setSending] = useState<boolean>(false)
  const [errors, setErrors] = useState<register.RegisterResponse>()
  const [marketingConsent, setMarketingConsent] = useState(false)
  const [myError, setMyError] = useState<string>('')

  const timeout = useRef<NodeJS.Timeout | null>(null)

  const updateModal = useModal()
  const checkIsMailAlreadyUsed = async (_email: string) => {
    try {
      const response = await POST<{ exists: boolean }>(url.checkemail, { body: { email: _email } })
      if (response.ok && response.data.exists) {
        showLoginForm(_email)
      }
    } catch (error) { }
  }

  const validate = () => {
    if (password !== checkPassword) {
      setMyError('Hasła nie są takie same')
      return false
    }
    setMyError('')
    return true
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    setEmail(value)
    if (value.length > 4) {
      timeout.current = setTimeout(() => {
        checkIsMailAlreadyUsed(value)
      }, DELAY)
    }
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    if (myError) {
      setMyError('')
    }
  }

  const handleCheckPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPassword(e.target.value)
    if (myError) {
      setMyError('')
    }
  }

  const handleSubmit = async () => {
    if (!validate()) return
    setSending(true)
    const body = {
      name,
      email,
      password,
      consent_advertising: marketingConsent
    }
    try {
      const response = await POST(url.register, { body })
      if (response.ok) {
        setSending(false)
        updateModal(
          <SentModal
            loginBody={body}
            title='Konto zarejestrowane'
            content='Proces rejestracji przebiegł pomyślnie, możesz się już zalogować'
            onClose={() => {
              updateModal()
            }}
          />
        )
      } else {
        setErrors(response.data.errors)
      }
    } catch (err) {
      console.error(err)
    }
    setSending(false)
  }

  return (
    <div>
      <div className="login__head">
        <h1>Zarejestruj się</h1>
      </div>

      <div className="login__body">
        {myError
          ? <div className="error">{myError}</div>
          : errors && Object.entries(errors).map(([field, fieldErrors]) =>
            fieldErrors?.map((err: string, index: number) => (
              <div key={`${field}-${index}`} className="error">
                {err}
              </div>
            ))
          )}
        <form>
          <div className="myInput">
            <label>Imię i nazwisko</label>
            <input
              type="text"
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </div>
          <div className="myInput">
            <label>E-mail</label>
            <input
              type="text"
              value={email}
              onChange={handleEmailChange}
              autoComplete="username"
            />
          </div>
          <div className="myInput">
            <label>Hasło</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete="new-password"
            />
          </div>
          <div className="myInput">
            <label>Powtórz hasło</label>
            <input
              type="password"
              value={checkPassword}
              onChange={handleCheckPasswordChange}
              autoComplete="new-password"
            />
          </div>
          <Checkbox
            content='(Opcjonalne) Wyrażam zgodę na treści marketingowe'
            checked={marketingConsent}
            onChanged={setMarketingConsent}
          />
          <p>Klikając przycisk Zarejestruj się, akceptujesz nasz <LinkToTerms type='terms'>Regulamin</LinkToTerms>. <LinkToTerms type='privacy'>Zasady ochrony prywatności</LinkToTerms> informują, w jaki sposób gromadzimy, użytkujemy i udostępniamy dane użytkowników.</p>
        </form>
        <div className='signin'>
          <div className="signin__wrap">
            Posiadasz konto? <span onClick={() => { showLoginForm() }}>Zaloguj się</span>
          </div>
        </div>
        <button
          onClick={handleSubmit}
          className="btn btn--primary">
          {sending ? "Wysyłanie..." : "Zarejestruj się"}
        </button>
      </div>

      <div className='or'>
        <span>Lub</span>
      </div>
      <SocialButtons />
      <FormFooter />
    </div>
  )
}
export default SigninForm