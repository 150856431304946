import { isIosDevice } from 'utils/devices'
import React, { useState } from 'react'
import Modal from './Modal'

interface IncompatibleBrowserModalProps {
  onClose: base.BasicFunc
}

const IncompatibleBrowserModal: React.FC<IncompatibleBrowserModalProps> = ({ onClose }) => {
  const [text, setText] = useState('Skopiuj link')
  const isIos = isIosDevice()
  return (
    <Modal
      title='Uwaga!'
      onClose={onClose}>
      <div className='orderInfo__modal'>
        <div className='infobox'>
          Korzystasz z niekompatybilnej przeglądarki. W celu prawidłowego działania otwórz stronę app.snapwish.pl w przeglądarce zewnętrznej, np. {isIos ? 'Safari' : 'Chrome'}
        </div>
        <div className='modal__footer'>
          <button
            className='btn btn--secondary'
            onClick={onClose}>
            Zamknij
          </button>
          <button
            className='btn btn--primary'
            onClick={() => {
              const link = window.location.href
              if (isIos) {
                window.location.href = `x-safari-${link}`
              } else if (navigator.clipboard) {
                navigator.clipboard.writeText(link).then(() => {
                  setText('Skopiowano')
                }, () => {
                  setText('Nie udało się skopiować')
                })
              }
            }}>
            {isIos ? 'Otwórz w przeglądarce' : text}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default IncompatibleBrowserModal