import React from 'react'
import './index.scss'

interface CheckboxProps {
  checked: boolean
  disabled?: boolean
  content: string | JSX.Element
  onChanged: (checked: boolean) => void
}

const Checkbox: React.FC<CheckboxProps> = ({ checked, disabled, content, onChanged }) => {
  return (
    <div
      className={`checkbox__container${disabled ? ' disabled' : ''}`}
      onClick={() => {
        if (!disabled) onChanged(!checked)
      }}>
      <div className={`checkbox${checked ? ' checked' : ''}`}>
        <i className='icon icon--check'></i>
      </div>
      <p>{content}</p>
    </div>
  )
}

export default Checkbox