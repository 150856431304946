import { useLocation, useNavigate } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from 'contexts/AuthContext'
import LoginForm from './Form'
import './index.scss'

interface LoginProps { }

const Login: React.FC<LoginProps> = () => {
  const { isAuth } = useAuth()
  const { search } = useLocation()

  const query = new URLSearchParams(search)
  const emailFromUrl = query.get('email')

  const [isAlert, showAlert] = useState(!!emailFromUrl)
  const [email, setEmail] = useState(emailFromUrl || '')

  const navigate = useNavigate()
  const formRef = useRef<HTMLDivElement>(null)


  useEffect(() => {
    if (isAuth) {
      navigate('/')
    }
  }, [isAuth, navigate])

  return (
    <div className='login'>
      <div className='login__desktop' />
      <div
        ref={formRef}
        className='login__form'>
        <LoginForm
          email={email}
          isAlert={isAlert}
          setEmail={setEmail}
          blockGuestLoginOptions
          hideAlert={() => {
            showAlert(false)
          }}
          showRegistrationForm={() => {
            navigate('/signin')
          }}
        />
      </div>
    </div>
  )
}

export default Login