import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { POST } from 'api/request'
import * as urls from 'api/urls'
import './index.scss'

const Verify = () => {
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false)
  const [isSending, setSending] = useState<boolean>(false)
  const [ceremonyID, setCeremonyID] = useState<string>('')

  const navigate = useNavigate()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  const handleSubmit = async () => {
    setSending(true)

    try {
      const response = await POST(urls.ceremoniesVerify, {
        body: {
          token: token
        }
      })
      if (response.ok) {
        console.log(response)
        if (response.data.data.isVerified) {
          setCeremonyID(response.data.data.id)
          setInfoVisible(true)
        }
      } else {
        setInfoVisible(false)
        setSending(false)
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSending(false)
    }
  }

  useEffect(() => {
    handleSubmit()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='verify'>
      {
        isSending
          ? <button className="btn btn--primary pending" >
            <i className="icon icon--pending"></i>
            Weryfikowanie...
          </button>
          : isInfoVisible
            ? <button className="btn btn--primary success" onClick={() => navigate(`/dashboard/${ceremonyID}`)}>
              <i className="icon icon--smile"></i>
              Udało się! Przejdź do wydarzenia
            </button>
            : <button className="btn btn--primary error" onClick={() => navigate('/')}>
              <i className="icon icon--sad"></i>
              Wystąpił błąd
            </button>
      }
    </div>
  )
}

export default Verify