import ModalTerms from 'components/Modal/TermsModal'
import ModalError, { Errors } from './modalError'
import { useNavigate } from 'react-router-dom'
import TopBar from 'components/Topbar'
import { POST } from 'api/request'
import { useState } from 'react'
import * as urls from 'api/urls'
import './index.scss'

type Modals = {
  type: 'error',
  content: Errors
} | {
  type: 'terms'
} | {
  type: 'privacy'
}

const New = () => {
  const [modal, setModal] = useState<Modals | null>(null)
  const [isInfoVisible, setInfoVisible] = useState<boolean>(false)
  const [isSending, setSending] = useState<boolean>(false)
  const [username, setUsername] = useState<string>('')
  const [mail, setMail] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [placeName, setPlaceName] = useState<string>('')
  const [placeAddress, setPlaceAddress] = useState<string>('')
  const [dateStart, setDateStart] = useState<string>('')
  const [dateEnd, setDateEnd] = useState<string>('')
  const [timeStart, setTimeStart] = useState<string>('')
  const [timeEnd, setTimeEnd] = useState<string>('')
  const [themeColor, setThemeColor] = useState<string>('#6C5FF3')
  const [canWish, setCanWish] = useState<boolean>()
  const [terms, setTerms] = useState<boolean>(false)

  const [mailError, setMailError] = useState<string>('')
  const [codeError, setCodeError] = useState<string>('')
  const [nameError, setNameError] = useState<string>('')
  const [usernameError, setUsernameError] = useState<string>('')
  const [descriptionError, setDescriptionError] = useState<string>('')
  const [placeNameError, setPlaceNameError] = useState<string>('')
  const [placeAddressError, setPlaceAddressError] = useState<string>('')
  const [dateStartError, setDateStartError] = useState<string>('')
  const [timeStartError, setTimeStartError] = useState<string>('')
  const [termsError, setTermsError] = useState<string>('')

  const colors = ['#6C5FF3', '#4994EC', '#CB3A8D', '#C85FE3', '#F2B347', '#1A5A20', '#1F2938']

  const navigate = useNavigate()

  const scrollToTop = () => {
    const appContainer = document.querySelector('.new')
    console.log('przed if')
    if (appContainer) {
      console.log('po if')
      appContainer.scrollTo({ top: 0, behavior: 'smooth' })
    }
  };

  const validateFields = () => {
    let errors = false

    if (!username.trim()) {
      setUsernameError('Nazwa użytkownika jest wymagana')
      errors = true
    } else if (username.length < 2) {
      setUsernameError('Nieprawidłowa nazwa użytkownika')
      errors = true
    } else {
      setUsernameError('')
    }

    if (code && (code.length < 6 || code.length > 16)) {
      setCodeError('Kod musi posiadać między 6 a 16 znaków')
      errors = true
    } else if (code && !/^[a-zA-Z0-9]+$/.test(code)) {
      setCodeError('Kod może zawierać tylko litery i cyfry')
      errors = true
    } else if (code && /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/.test(code)) {
      setCodeError('Kod nie może zawierać polskich znaków');
      errors = true;
    } else {
      setCodeError('')
    }

    if (!mail.trim()) {
      setMailError('E-mail jest wymagany')
      errors = true
    } else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(mail)) {
      setMailError('Nieprawidłowy adres e-mail')
      errors = true
    } else {
      setMailError('')
    }

    if (!name.trim()) {
      setNameError('Nazwa jest wymagany')
      errors = true
    } else {
      setNameError('')
    }
    if (!description.trim()) {
      setDescriptionError('Opis jest wymagany')
      errors = true
    } else {
      setDescriptionError('')
    }
    if (!placeName.trim()) {
      setPlaceNameError('Nazwa miejsca jest wymagany')
      errors = true
    } else {
      setPlaceNameError('')
    }
    if (!placeAddress.trim()) {
      setPlaceAddressError('Adres miejsca jest wymagany')
      errors = true
    } else {
      setPlaceAddressError('')
    }
    if (!dateStart.trim()) {
      setDateStartError('Data rozpoczęcia jest wymagana')
      errors = true
    } else {
      setDateStartError('')
    }
    if (!timeStart.trim()) {
      setTimeStartError('Czas rozpoczęcia jest wymagany')
      errors = true
    } else {
      setTimeStartError('')
    }

    if (!terms) {
      errors = true
      setTermsError('Musisz wyrazić zgodę')
    } else {
      setTermsError('')
    }

    if (errors) {
      scrollToTop()
    }

    return !errors
  }

  const handleSubmit = async () => {
    if (!validateFields()) {
      return
    }
    setSending(true)

    try {
      const response = await POST(urls.ceremonies, {
        body: {
          username,
          code: code,
          name: name,
          email: mail,
          description: description,
          place_name: placeName,
          place_address: placeAddress,
          date_start: dateStart,
          date_end: dateEnd,
          time_start: timeStart,
          time_end: timeEnd,
          theme_color: themeColor,
          can_wish: canWish
        }
      })
      if (response.ok) {
        setInfoVisible(true)
      } else if (response.data.errors) {
        setModal({ type: 'error', content: response.data.errors })
      } else {
        alert('Coś się popsuło :/')
      }
    } catch (err) {
      console.error(err)
    } finally {
      setSending(false)
    }
  }

  return (
    <>
      {modal?.type === 'error' && <ModalError
        data={modal.content}
        onClose={() => setModal(null)}
      />}
      {(modal?.type === 'privacy' || modal?.type === 'terms') && <ModalTerms
        type={modal.type}
        onClose={() => setModal(null)}
      />}
      <div className='new'>
        <TopBar title="Utwórz wydarzenie" isSticky theme="dark" />
        <div className="new__content">

          {isInfoVisible
            ? <div className='new__sent'>
              <i className="icon icon--send s64"></i>
              <p className='sendInfo'>
                Wejdź na swojego maila, aby potwierdzic utworzenie wydarzenia
              </p>
              <div className='btn btn--primary' onClick={() => navigate('/')}>
                Wróć do strony głównej
              </div>
            </div>
            : <>
              <div className={`input ${usernameError && 'error'}`}>
                <label>{usernameError ? usernameError : 'Imię i nazwisko organizatora'}</label>
                <input
                  type="text"
                  placeholder='np. Piotr Kowalski'
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value)
                    if (usernameError) {
                      validateFields()
                    }
                  }} />
              </div>

              <div className={`input ${mailError && 'error'}`}>
                <label>{mailError ? mailError : 'E-mail organizatora'}</label>
                <input
                  type="text"
                  placeholder='np. piotrkowalski@mail.pl'
                  value={mail}
                  onChange={(e) => {
                    setMail(e.target.value)
                    if (mailError) {
                      validateFields()
                    }
                  }} />
              </div>

              <div className={`input ${codeError && 'error'}`}>
                <div><label>{codeError ? codeError : 'Kod QR wydarzenia'}</label></div>
                <label>Nie masz pomysłu na kod, możemy go wygenerować za Ciebie 😉</label>
                <input
                  type="text"
                  placeholder='np. Janek18'
                  value={code}
                  onChange={(e) => {
                    setCode(e.target.value)
                    if (codeError) {
                      validateFields()
                    }
                  }} />
              </div>

              <div className={`input ${nameError && 'error'}`}>
                <label>{nameError ? nameError : 'Nazwa wydarzenia'}</label>
                <input
                  type="text"
                  placeholder='np. 18 Urodziny Janka'
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value)
                    if (nameError) {
                      validateFields()
                    }
                  }} />
              </div>

              <div className={`input ${placeNameError && 'error'}`}>
                <label>{placeNameError ? placeNameError : 'Nazwa lokalizacji'}</label>
                <input
                  type="text"
                  placeholder='np. Resturacja Aria'
                  value={placeName}
                  onChange={(e) => {
                    setPlaceName(e.target.value)
                    if (placeNameError) {
                      validateFields()
                    }
                  }} />
              </div>

              <div className={`input ${placeAddressError && 'error'}`}>
                <label>{placeAddressError ? placeAddressError : 'Adres lokalizacji'}</label>
                <input
                  type="text"
                  placeholder='Gdańsk, ul. Kwiatowa 13'
                  value={placeAddress}
                  onChange={(e) => {
                    setPlaceAddress(e.target.value)
                    if (placeAddressError) {
                      validateFields()
                    }
                  }} />
              </div>

              <div className={`input ${dateStartError && 'error'}`}>
                <label>{dateStartError ? dateStartError : 'Data rozpoczęcia'}</label>
                <input
                  type="date"
                  placeholder='Data rozpoczęcia'
                  value={dateStart}
                  onChange={(e) => {
                    setDateStart(e.target.value)
                    if (dateStartError) {
                      validateFields()
                    }
                  }} />
              </div>

              <div className={`input ${timeStartError && 'error'}`}>
                <label>{timeStartError ? timeStartError : 'Godzina rozpoczęcia'}</label>
                <input
                  type="time"
                  placeholder='Godzina rozpoczęcia'
                  value={timeStart}
                  onChange={(e) => {
                    setTimeStart(e.target.value)
                    if (timeStartError) {
                      validateFields()
                    }
                  }} />
              </div>

              <div className={`input`}>
                <label>{'Data zakończenia'}</label>
                <input
                  type="date"
                  placeholder='Data zakończenia'
                  value={dateEnd}
                  onChange={(e) => {
                    setDateEnd(e.target.value)
                  }} />
              </div>

              <div className={`input`}>
                <label>{'Godzina zakończenia'}</label>
                <input
                  type="time"
                  placeholder='Godzina zakończenia'
                  value={timeEnd}
                  onChange={(e) => {
                    setTimeEnd(e.target.value)
                  }} />
              </div>

              <div className='input'>
                <label>Motyw kolorystyczny</label>
                <div className='colorSelect'>
                  {colors.map(color => (
                    <div
                      key={color}
                      style={{ backgroundColor: color }}
                      className='colorOption'
                      onClick={() => setThemeColor(color)}
                    >
                      {themeColor === color && <i className="icon icon--check s32"></i>}
                    </div>
                  ))}
                </div>
              </div>

              <div className='input'>
                <label>Czy można wysyłać życzenia</label>
                <div className='toggleBtn' onClick={() => setCanWish(!canWish)}>
                  <span>{canWish ? 'Tak' : 'Nie'}</span>
                  <div className={`toggle ${canWish ? 'active' : ''}`}>
                    <div className="circle"></div>
                  </div>
                </div>
              </div>


              <div className={`textarea ${descriptionError && 'error'}`}>
                <label>{descriptionError ? descriptionError : 'Opis wydarzenia'}</label>
                <textarea
                  placeholder='Krótki opis wydarzenia'
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value)
                    if (descriptionError) {
                      validateFields()
                    }
                  }}
                />
              </div>

              <div className={`input ${termsError && 'error'}`}>
                <label>Zapoznałem się z <span
                  className='input__link'
                  onClick={e => {
                    e.preventDefault()
                    setModal({ type: 'privacy' })
                  }}>Polityką prywatności</span> i <span
                    className='input__link'
                    onClick={e => {
                      e.preventDefault()
                      setModal({ type: 'terms' })
                    }}>regulaminem</span> aplikacji{termsError ? ` - ${termsError}` : ''}</label>
                <div className='toggleBtn' onClick={() => setTerms(!terms)}>
                  <span>{terms ? 'Tak' : 'Nie'}</span>
                  <div className={`toggle ${terms ? 'active' : ''}`}>
                    <div className="circle"></div>
                  </div>
                </div>
              </div>

              <div className={`btn btn--primary ${isSending && 'btn--sending'}`} onClick={isSending ? undefined : handleSubmit}>
                {isSending
                  ? 'Wysłanie...'
                  : 'Utwórz wydarzenie'
                }
              </div>

            </>}
        </div>
      </div>
    </>)
}

export default New