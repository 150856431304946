import { useAuth } from 'contexts/AuthContext'
import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'
import Loader from 'components/Loader'
import { POST } from 'api/request'
import * as urls from 'api/urls'

const AppleCallback: React.FC = () => {
  const { logInOut } = useAuth()
  const navigate = useNavigate()
  const signInWithApple = async () => {
    try {
      const query = new URLSearchParams(window.location.search)
      const code = query.get('code')
      if (code) {
        const response = await POST(urls.appleCallback, { body: { code } })
        if (response.ok && response.data?.token.accessToken) {
          logInOut(response.data, true)

        } else {
          throw new Error('Wrong response!')
        }

      } else {
        throw new Error('Wrong callback!')
      }
    } catch (error) {
      navigate('/login')
    }
  }

  useEffect(() => {
    signInWithApple()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Loader type='full' />
  )
}

export default AppleCallback