import { useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import Modal from 'components/Modal/Modal'
import React from 'react'

interface LoginBody {
  email: string
  password: string
}

interface SentModalProps {
  title: string
  content: string
  loginBody?: LoginBody
  onClose: base.BasicFunc
}

const SentModal: React.FC<SentModalProps> = ({ title, content, loginBody, onClose }) => {
  const { isLoading, logIn } = useAuth()
  const navigate = useNavigate()

  const loginUser = () => {
    if (!loginBody) return
    logIn(loginBody, true).then(res => {
      if (res?.ok) {
        onClose()
      }
    })
  }

  return (
    <Modal
      title={title}
      onClose={() => {
        onClose()
      }}>
      <div className="modal__sentModal --horizontalPadding">
        <p>{content}</p>
        <div className='modal__footer'>
          <button
            className='btn btn--secondary'
            onClick={() => {
              onClose()
            }}>
            Zamknij
          </button>
          <button
            className='btn btn--primary'
            onClick={() => {
              if (loginBody) {
                loginUser()
              } else {
                navigate('/login')
              }
            }}>
            {isLoading ? 'ładowanie...' : loginBody ? 'Zaloguj się' : 'Przejdź do logowania'}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default SentModal