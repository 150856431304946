import { useNavigate } from 'react-router-dom'
import { useAuth } from 'contexts/AuthContext'
import SocialButtons from './SocialButtons'
import React, { useState } from 'react'
import FormFooter from './FormFooter'
import { POST } from 'api/request'
import * as url from 'api/urls'

interface LoginFormProps {
  email: string
  isAlert: boolean
  blockGuestLoginOptions: boolean
  hideAlert: base.BasicFunc
  setEmail: React.Dispatch<React.SetStateAction<string>>
  showRegistrationForm: base.BasicFunc
}

const LoginForm: React.FC<LoginFormProps> = ({ isAlert, email, blockGuestLoginOptions, hideAlert, setEmail, showRegistrationForm }) => {
  const [code, setCode] = useState('')
  const [error, setErrors] = useState()
  const [password, setPassword] = useState('')

  const { logIn, setUserAsGuest } = useAuth()

  const navigate = useNavigate()
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const handleSubmit = async () => {
    if (isAlert) hideAlert()
    logIn({ email, password }, true).then((res) => {
      if (!res?.ok && res?.data?.message) {
        setErrors(res.data.message)
      }
    })
  }

  const handleLoginAsGuest = () => {
    setUserAsGuest(true)
  }

  const submit = async () => {
    try {
      const response = await POST(url.qrs, { body: { code } })
      if (response.ok) {
        alert('poszło')
      }
      else {
        alert('error')
      }
    } catch (error) {
      alert('error')
    }
  }

  return (
    <div>
      <div className="login__head">
        <h1>
          <div>Zaloguj się</div>
        </h1>
      </div>

      {isAlert && <div className='error'>Konto już istnieje!</div>}
      {error && <div className='error'>Nieprawidłowe dane</div>}

      <div className="login__body">
        <form>
          <div className="myInput">
            <label>Podaj E-mail</label>
            <input
              type="text"
              value={email}
              onChange={handleEmailChange}
              autoComplete='username'
            />
          </div>
          <div className="myInput">
            <label>Podaj hasło</label>
            <input
              type="password"
              value={password}
              onChange={handlePasswordChange}
              autoComplete='current-password'
            />
          </div>
        </form>
        <div className='signin'>
          <div className="signin__wrap">
            Nie posiadasz konta? <span onClick={() => { showRegistrationForm() }}>Zarejestruj się</span>
          </div>
          <div className="signin__wrap">
            <span onClick={() => navigate('/reset')}>Przypomnij hasło</span>
          </div>
        </div>
        <button
          className='btn btn--primary'
          onClick={handleSubmit}>
          Zaloguj się
        </button>
        {!blockGuestLoginOptions && <button
          className='btn btn--secondary'
          onClick={handleLoginAsGuest}>
          Kontynuuj jako gość
        </button>}
      </div>
      <div className='or'>
        <span>Lub</span>
      </div>
      <SocialButtons />

      <div style={{ display: 'none' }}>
        <div className="myInput">
          <label>Dodaj QR do bazy danych</label>
          <input
            type="text"
            placeholder='wpisz kod'
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <button onClick={submit}>Submit</button>
        </div>
      </div>
      <FormFooter />
    </div>
  )
}

export default LoginForm