import TermsModal from 'components/Modal/TermsModal'
import { useModal } from 'contexts/ModalContext'
import React from 'react'

const FormFooter: React.FC = () => {
  const updateModal = useModal()
  const showTermsModal = (type: 'privacy' | 'terms') => {
    updateModal(
      <TermsModal
        type={type}
        onClose={() => {
          updateModal()
        }}
      />
    )
  }

  return (
    <div className="login__form__footer">
      <span onClick={() => {
        showTermsModal('privacy')
      }}>
        Polityka prywatności
      </span>
      <span onClick={() => {
        showTermsModal('terms')
      }}>
        Regulamin
      </span>
    </div>
  )
}

export default FormFooter