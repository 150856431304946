import { useLocation, useNavigate } from "react-router-dom"
import { useAuth } from "contexts/AuthContext"
import Loader from "components/Loader"
import { POST } from 'api/request'
import { useEffect } from 'react'
import * as url from 'api/urls'

const GoogleCallback = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const auth = useAuth()

  const logIn = async (search: string) => {
    try {
      const response = await POST(`${url.googleCallback}${search}`)
      if (response.ok && response.data?.token.accessToken) {
        auth.logInOut(response.data, true)
      } else {
        throw new Error('Wrong response!')
      }
    } catch (error) {
      console.error('🤬')
      navigate('/login')
    }
  }

  useEffect(() => {
    logIn(location.search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  return <Loader type="full" />

}

export default GoogleCallback