import { BrowserRouter as Router } from 'react-router-dom'
import { CeremonyProvider } from 'contexts/CermonyContext'
import { ConfigProvider } from 'contexts/ConfigContext'
import { ModalProvider } from 'contexts/ModalContext'
import { AlertProvider } from 'contexts/AlertContext'
import { AuthProvider } from 'contexts/AuthContext'
import DataProvider from 'contexts/DataContext'
import ReactDOM from 'react-dom/client'
import Alert from 'components/Alert'
import App from './App'
import './index.scss'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  // <React.StrictMode>
  <AlertProvider>
    <CeremonyProvider>
      <DataProvider>
        <Router>
          <Alert />
          <AuthProvider>
            <ModalProvider>
              <ConfigProvider>
                <App />
              </ConfigProvider>
            </ModalProvider>
          </AuthProvider>
        </Router>
      </DataProvider>
    </CeremonyProvider>
  </AlertProvider>
  // </React.StrictMode>
)
