import FormFooter from 'pages/Login/FormFooter'
import SentModal from 'pages/Signin/SentModal'
import { useLocation } from 'react-router-dom'
import { POST } from 'api/request'
import { useState } from 'react'
import * as url from 'api/urls'

const SetNewPassword: React.FC = () => {
  const [password, setPassword] = useState('')
  const [checkPassword, setCheckPassword] = useState('')
  const [modal, setModal] = useState<boolean>(false)
  const [sending, setSending] = useState<boolean>(false)
  const [errors, setErrors] = useState<register.RegisterResponse>()
  const [myError, setMyError] = useState<string>('')

  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const token = queryParams.get('token');

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
    if (myError) {
      setMyError('')
    }
  }
  const handleCheckPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCheckPassword(e.target.value)
    if (myError) {
      setMyError('')
    }
  }

  const validate = () => {
    if (password !== checkPassword) {
      setMyError('Hasła nie są takie same')
      return false
    }
    setMyError('')
    return true
  }

  const handleSubmit = async () => {
    if (!validate()) return
    setSending(true)
    try {
      const response = await POST(url.resetPassword, {
        body: {
          email,
          token,
          password
        }
      })
      if (response.ok) {
        setModal(true)
        setSending(false)
      } else {
        setErrors(response.data.errors)
      }
    } catch (err) {
      console.error(err)
    }
    setSending(false)
  }

  return (
    <>
      {modal && <SentModal title='Hasło ustawione!' content='Proces resetu hasła przebiegł pomyślnie, możesz się już zalogować nowym hasłem' onClose={() => setModal(false)} />}
      <div className="login">
        <div className="login__desktop"></div>
        <div className="login__form">
          <div>
            <div className="login__head">
              <h1>Wprowadź nowe hasło</h1>
            </div>

            <div className="login__body grow">
              {myError
                ? <div className="error">{myError}</div>
                : errors && Object.entries(errors).map(([field, fieldErrors]) =>
                  fieldErrors?.map((err: string, index: number) => (
                    <div key={`${field}-${index}`} className="error">
                      {err}
                    </div>
                  ))
                )}
              <form>
                <div className="myInput">
                  <label>Podaj nowe hasło</label>
                  <input
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    autoComplete="new-password"
                  />
                </div>
                <div className="myInput">
                  <label>Powtórz nowe hasło</label>
                  <input
                    type="password"
                    value={checkPassword}
                    onChange={handleCheckPasswordChange}
                    autoComplete="new-password"
                  />
                </div>
              </form>
              <button onClick={handleSubmit} className="btn btn--primary">{sending ? "Wysyłanie..." : "Zresetuj hasło"}</button>
            </div>
            <FormFooter />
          </div>
        </div>
      </div>
    </>)
}

export default SetNewPassword