import TermsModal from 'components/Modal/TermsModal'
import { useModal } from 'contexts/ModalContext'
import React from 'react'

interface LinkToTermsProps {
  children: string | JSX.Element
  type: 'terms' | 'privacy'
}

const LinkToTerms: React.FC<LinkToTermsProps> = ({ children, type }) => {
  const showModal = useModal()
  return (
    <span
      style={{
        color: 'var(--text-color)',
        cursor: 'pointer'
      }}
      onClick={e => {
        e.stopPropagation()
        showModal(
          <TermsModal
            type={type}
            onClose={() => {
              showModal()
            }}
          />
        )
      }}>
      {children}
    </span>
  )
}

export default LinkToTerms