import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import SigninForm from './Form'

const Signin: React.FC = () => {
  const [email, setEmail] = useState('')
  const navigate = useNavigate()

  return (
    <div className="login">
      <div className="login__desktop"></div>
      <div className='login__form'>
        <SigninForm
          email={email}
          setEmail={setEmail}
          showLoginForm={(email) => {
            navigate(`/login${email ? `?email=${email}` : ''}`)
          }}
        />
      </div>
    </div>
  )
}
export default Signin