import IncompatibleBrowserModal from 'components/Modal/IncompatibleBrowserModal'
import { useConfig } from 'contexts/ConfigContext'
import { useModal } from 'contexts/ModalContext'
import { isAppleDevice } from 'utils/devices'
import * as nativeApp from 'utils/nativeApp'
import InAppSpy from 'inapp-spy'
import React from 'react'

const SocialButtons: React.FC = () => {
  const isNativeApp = nativeApp.isNativeApp()
  const config = useConfig()
  const appSpy = InAppSpy()

  const showModal = useModal()
  const showIncompatibleBrowserModal = () => {
    showModal(
      <IncompatibleBrowserModal
        onClose={() => {
          showModal()
        }}
      />
    )
  }

  return (
    <div className="login__footer">
      {config?.oauth.apple && isAppleDevice() && <button
        className='btn btn--social'
        onClick={() => {
          window.open(config.oauth.apple, "_self")
        }}>
        <i className='icon icon--apple' />
        Kontynuuj, używając konta Apple
      </button>}
      {config?.oauth.google && <button
        className={`btn btn--social${!isNativeApp && appSpy.isInApp ? ' disabled' : ''}`}
        onClick={() => {
          if (isNativeApp) {
            nativeApp.openAuth(config.oauth.google!)
          } else if (appSpy.isInApp) {
            showIncompatibleBrowserModal()
          } else {
            window.open(config.oauth.google, "_self")
          }
        }}>
        <i className='icon icon--google' />
        Kontynuuj, używając konta Google
      </button>}
      {config?.oauth.facebook && <button
        className='btn btn--facebook'
        onClick={() => {
          window.open(config.oauth.facebook, "_self")
        }}>
        <i className='icon icon--apple' />
        Kontynuuj, używając konta Facebook
      </button>}
    </div>
  )
}

export default SocialButtons