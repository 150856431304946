import React, { createContext, useContext, useState, ReactNode } from 'react'
import { loadState, removeItem, saveState } from 'utils/storage'
import RequestManager from 'api/HeadersManager'
import { useNavigate } from 'react-router-dom'
import { POST } from 'api/request'
import * as url from 'api/urls'

const AuthContext = createContext<auth.AuthContext | undefined>(undefined)
const FIFTEEN_MINUTES = 1000 * 60 * 15
export const REDIRECT_PAGE_KEY = 'redirect_page'
export const REDIRECT_PAGE_SESSION = false
export const AUTH_TOKEN_KEY = 'auth_token'
export const AUTH_TOKEN_SESSION = false

export const getInitialAuth = (isAuthInProcess = true) => {
  const state: auth.AuthState = {
    isAuth: false,
    isGuest: false,
    isAuthInProcess,
    expiresAt: 0
  }
  return state
}

export const generateAuthDataFromResponse = (response: auth.LoginResponse) => {
  const token = response.token.accessToken
  let expiresAt = 'expiresIn' in response.token
    ? (Date.now() + (response.token.expiresIn * 1000))
    : response.token?.token && 'expires_at' in response.token.token
      ? new Date(response.token.token.expires_at).getTime()
      : 0
  return {
    token,
    isAuth: true,
    isGuest: false,
    isAuthInProcess: false,
    user: response.user,
    expiresAt
  }
}

const validateStoredAuth = (auth: auth.AuthState) => {
  if (!auth.isAuth || !auth.token || !auth.expiresAt) return false
  return auth.expiresAt > Date.now() + FIFTEEN_MINUTES
}

const getAuthState = () => {
  const initialAuth = getInitialAuth()
  const auth = loadState<auth.AuthState>(AUTH_TOKEN_SESSION, AUTH_TOKEN_KEY, initialAuth)
  if (validateStoredAuth(auth)) {
    RequestManager.jwt.set(auth.token!)
    return auth
  } else {
    return initialAuth
  }
}

const useAuthState = () => {
  const [data, setData] = useState<auth.AuthState>(getAuthState)
  const updateData: React.Dispatch<React.SetStateAction<auth.AuthState>> = getState => {
    setData(prev => {
      const state = typeof getState === 'function' ? getState(prev) : getState
      saveState(state, AUTH_TOKEN_SESSION, AUTH_TOKEN_KEY)
      return state
    })
  }

  return [
    data,
    updateData
  ] as const
}

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [data, setData] = useAuthState()

  const navigate = useNavigate()
  const loadRedirectPage = () => {
    const redirect = loadState<auth.Redirect>(REDIRECT_PAGE_SESSION, REDIRECT_PAGE_KEY, { pathname: '' })
    if (redirect.pathname) {
      removeItem(REDIRECT_PAGE_SESSION, REDIRECT_PAGE_KEY)
      navigate(redirect.pathname, { state: redirect.state, replace: true })
    } else if (window.location.pathname !== '/') {
      navigate('/', { replace: true })
    }
  }

  const logInOut = (response?: auth.LoginResponse, autoRedirect?: boolean) => {
    if (response?.token?.accessToken) {
      const newData = generateAuthDataFromResponse(response)
      RequestManager.jwt.set(newData.token)
      setData(newData)
      if (autoRedirect) {
        loadRedirectPage()
      }

    } else {
      RequestManager.jwt.destroy()
      setData({
        isAuth: false,
        isGuest: false,
        isAuthInProcess: false,
        expiresAt: 0
      })
    }
  }

  const setUserAsGuest = (isGuest = false) => {
    if (isGuest) loadRedirectPage()
    setData({
      isGuest,
      isAuth: false,
      isAuthInProcess: false,
      expiresAt: 0
    })
  }

  const refreshUserData = (user: Partial<auth.AuthUser>) => {
    setData(prev => ({
      ...prev,
      user: prev.user ? { ...prev.user, ...user } : undefined
    }))
  }

  return (
    <AuthContext.Provider value={{ ...data, logInOut, refreshUserData, setUserAsGuest }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const context = useContext(AuthContext)
  const navigate = useNavigate()

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }

  const authUser = async (endpoint: string, body?: object, autoRedirect?: boolean) => {
    setLoading(true)
    try {
      const response = await POST(endpoint, { body, credentials: true })
      const thereIsToken = 'token' in response.data
      if (endpoint !== url.refresh || thereIsToken) {
        context.logInOut(thereIsToken ? response.data : undefined, autoRedirect)
      } else if (context.isAuthInProcess) {
        context.setUserAsGuest(context.isGuest)
      }
      setLoading(false)
      return response
    } catch (error) {
      setLoading(false)
    }
  }

  const logIn = async (body: { email: string, password: string }, autoRedirect = true) => {
    return await authUser(url.login, body, autoRedirect)
  }

  const saveRedirectPage = (pathname: string, state?: object) => {
    saveState({ pathname, state }, REDIRECT_PAGE_SESSION, REDIRECT_PAGE_KEY)
  }

  const refreshToken = async () => {
    if (context.isAuth || context.isGuest) return
    return await authUser(url.refresh)
  }

  const logOut = async () => {
    try {
      await POST(url.logout)
      context.logInOut()
      navigate('/')

    } catch (error) {
      context.logInOut()
      navigate('/')
    }
  }

  return {
    ...context,
    isLoading,
    logIn,
    logOut,
    refreshToken,
    saveRedirectPage
  }
}