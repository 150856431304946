import { useNavigate } from 'react-router-dom'
import React, { useEffect } from 'react'

export type Errors = Record<string, string[]>
interface ModalErrorProps {
  data: Errors
  onClose: () => void
}

const ModalError: React.FC<ModalErrorProps> = ({ data, onClose }) => {
  const navigate = useNavigate()

  useEffect(() => {
    const handlePopState = () => {
      onClose()
    }

    window.history.pushState(null, '', window.location.pathname)
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="modal" style={{ zIndex: '999999999' }}>
      <div className='modal__cover active' onClick={() => navigate(-1)}></div>
      <div className={`modal__content active`}>
        <div className="modal__title">
          <div className="close" onClick={() => navigate(-1)}>
            <i className="icon icon--close s24"></i>
          </div>
          <h1>Wystąpił błąd</h1>
        </div>

        <div className="modal__body">
          <div className='modal__multipleMedia'>
            <p className='sendInfo' style={{ minHeight: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {Object.entries(data).map(([key, error]) => <span
                key={key}>
                {error.map((e, i) => <span key={i}>{e}</span>)}
              </span>)}
            </p>
            <div className="footer footer--double">
              <div className="btn btn--primary" onClick={() => navigate(-1)}>
                Rozumiem
              </div>
            </div>
          </div>
        </div>

      </div >
    </div >
  )
}

export default ModalError