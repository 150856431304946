import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'
import { GET } from 'api/request'
import * as urls from 'api/urls'

interface ConfigOauth {
  facebook?: string
  google?: string
  apple?: string
}

interface Config {
  appName: string
  oauth: ConfigOauth
}

export const ConfigContext = createContext<Config | null>(null)

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [config, setConfig] = useState<Config | null>(null)

  const getConfig = async () => {
    try {
      const response = await GET<Config>(urls.config)
      if (response.ok) {
        setConfig(response.data)
      }
    } catch (error) {
      console.error(`Fetch failed: ${error}`)
    }
  }

  useEffect(() => {
    getConfig()
  }, [])

  return (
    <ConfigContext.Provider value={config}>
      {children}
    </ConfigContext.Provider>
  )
}

export const useConfig = () => {
  return useContext(ConfigContext)
}
